import * as React from 'react';
import { StyledTeamMembers } from './team-members.styles';
import { graphql } from 'gatsby';
import TeamMember from '../../parts/team-member';
import { useTeamMembers } from '../../hooks/team-members.hook';
import { Maybe, TeamMemberFragmentFragment, TeamMembersBlockFragmentFragment } from '../../../gatsby-graphql';
import { BaseBlockProps } from '../blocks';

export interface ITeamMembersBlockProps extends TeamMembersBlockFragmentFragment, BaseBlockProps {}

export const TeamMembersBlock: React.FC<ITeamMembersBlockProps> = props => {
  const teamMembers = useTeamMembers();
  const memberIds = props.innerBlocks && props.innerBlocks.map((member) => {
      return member.attributes?.memberId;
    });

  const choosenMembers = teamMembers && memberIds && teamMembers.filter((member: Maybe<TeamMemberFragmentFragment>) => {
      return memberIds.includes(member?.databaseId);
    }
    );

  return (
    <StyledTeamMembers>
      {memberIds && memberIds.map((memberId?: Maybe<number>, index?: number) => {
          const teamMember = choosenMembers && choosenMembers.find((teamMember: Maybe<TeamMemberFragmentFragment>, index) => 
              memberId === teamMember?.databaseId
          );

          return (
            teamMember && <TeamMember {...teamMember} key={index} />
          )
        })
      }
    </StyledTeamMembers>
  )
};

export const TeamMemberBlockFragment = graphql`
  fragment TeamMemberBlockFragment on WpSafetyFirstPluginTeamMemberBlock {
    attributes {
      memberId
    }
  }
`;

export const TeamMembersBlockFragment = graphql`
  fragment TeamMembersBlockFragment on WpSafetyFirstPluginTeamMembersBlock {
    innerBlocks {
      ...TeamMemberBlockFragment
    }
  }
`;

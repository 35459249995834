import styled from 'styled-components';
import { animation } from '../../global/animation.styles';
import { ImageScrimHoverStyle, ImageScrimStyle, ImageWithTextScrimHoverStyle, ImageWithTextScrimStyle } from '../../global/placeholders';
import { colors, themedColor } from '../../global/theme.styles';
import { StyledImage } from '../../parts/image.styles';
import { StyledLink } from '../../parts/link.styles';
import { h2Styles, h3Styles } from '../../global/typography.styles';

export interface IStyledImageBlock {
    inColumn?: boolean;
    hasText?: boolean;
}

export const StyledImageBlock = styled.div<IStyledImageBlock>`

    img {
        width: 100%;
    }

    & ${StyledLink} {

        & ${StyledImage} {
            ${p => p.hasText ? ImageWithTextScrimStyle({hidden: false}) : ImageScrimStyle({hidden: false})}
            height: 100%;
            width: 100%;
            overflow: hidden;

            & .gatsby-image-wrapper {
                transform: scale(1);
                transform-origin: center;
                transition: transform ${animation.hoverImageZoom};
            }
        }

        &:hover {
            & ${StyledImage} {
                ${p => p.hasText ? ImageWithTextScrimHoverStyle({lighten: true}) : ImageScrimHoverStyle({lighten: true})}

                & .gatsby-image-wrapper {
                    transform: scale(1.1);
                    transform-origin: center;
                }
            }
        }

        & figcaption {
            color: ${colors.white};
            ${p => p.inColumn ? h3Styles : h2Styles}
            position: absolute;
            bottom: 0;
        }
    }
`;

import { ImageRatios } from "../parts/image";
import { useLocation } from "@reach/router";
import { FileSystemNode } from "gatsby-source-filesystem";
import { getSrc, IGatsbyImageData } from "gatsby-plugin-image";
import { ImageFragmentFragment } from "../../gatsby-graphql";
import { FileNode, IGatsbyImageDataParent } from "gatsby-plugin-image/dist/src/components/hooks";

export const getFeaturedImage = (src?: FileNode | IGatsbyImageDataParent | IGatsbyImageData) => {
    const location = useLocation();
    const imageSRC = getDefaultImage(src);

    // TODO: add default featured image
    return location.origin + imageSRC ? imageSRC : '';
}

export const getDefaultImage = (src?: FileNode | IGatsbyImageDataParent | IGatsbyImageData) => {
    const gatsbyImage = src && getSrc(src);

    return gatsbyImage;
}

export const getImageRatioByNumber = (numberOnPage: number) => {
    if (! (numberOnPage % 5 )  ) {
        return 'double';
    } else if (! (numberOnPage % 4 )) {
        return 'tall';
    } else if (! (numberOnPage % 3 )) {
        return 'tall';
    } else if (! (numberOnPage % 2 )) {
        return 'tall';
    } else if (! (numberOnPage % 1 )) {
        return 'stout';
    }

    return 'default';
}
import * as React from 'react';
import { graphql } from 'gatsby';
import { SocialLinksBlockFragmentFragment } from '../../../gatsby-graphql';
import FacebookIcon from "../../svg/facebook-icon.svg";
import LinkedInIcon from "../../svg/linkedin-icon.svg";
import GoogleIcon from "../../svg/google-icon.svg";
import { StyledSocialLinks } from './social-links.styles';
import { LinkPart } from '../../parts/link';
import { useSocialLinks } from '../../hooks/social-links.hook';
import { ThemeProvider } from 'styled-components';
import { siteTheme } from '../../global/theme.styles';
import { BaseBlockProps } from '../blocks';

export interface ISocialLinksBlockProps extends SocialLinksBlockFragmentFragment, BaseBlockProps {};

export const SocialLinksBlock: React.FC<ISocialLinksBlockProps> = () => {
    const menuItems = useSocialLinks();

    return (
        <ThemeProvider theme={siteTheme.primaryComponentTheme}>
        <StyledSocialLinks>
            <ul className="social-links">
                {menuItems?.nodes && menuItems.nodes.map(item => {
                const url= item?.connectedNode && item.connectedNode.node?.slug ? item.connectedNode.node.slug : item?.url;
                let icon: any | undefined = undefined;
                switch(item?.label) {
                    case "Facebook":
                        icon = <FacebookIcon />
                        break;
                    case "LinkedIn":
                        icon = <LinkedInIcon />
                        break;
                    case "Google":
                        icon = <GoogleIcon />
                        break;
                }

                return (
                    <li className="social-links__item" key={item?.id}>
                    <LinkPart className="social-links__link" to={url || ''}>
                        {icon}
                    </LinkPart>
                    </li>
                );
                })}
            </ul>
        </StyledSocialLinks>
        </ThemeProvider>
    );
};

export const SocialLinksBlockFragment = graphql`
    fragment SocialLinksBlockFragment on WpCoreSocialLinksBlock {
        name
    }
`;

import * as React from 'react';
import { StyledMediaTextBlock } from './media-text.styles';
import { ImagePart } from '../../parts/image';
import { ParagraphBlock } from './paragraph';
import { graphql } from 'gatsby';
import { getImageRatioByNumber } from '../../helpers/image';
import { isOdd } from '../../helpers/operations';
import { HeadingBlock } from './heading';
import { ListBlock } from './list';
import { QuoteBlock } from './quote';
import { PullQuoteBlock } from './pullquote';
import { VerseBlock } from './verse';
import { ButtonsBlock } from './buttons';
import { InViewElement } from '../../parts/in-view-element';
import { MediaTextBlockFragmentFragment } from '../../../gatsby-graphql';
import { BaseBlockProps } from '../blocks';

export interface IMediaTextBlockProps extends MediaTextBlockFragmentFragment, BaseBlockProps {};

export const MediaTextBlock: React.FC<IMediaTextBlockProps> = props => {

  return (
    <StyledMediaTextBlock reversed={!isOdd(props.numberOfType || 0)} verticalAlign={props.attributes?.verticalAlignment || undefined}>
        <div className="media-text__image">
          <InViewElement>
            <ImagePart 
              src={props.attributes?.src?.childImageSharp?.gatsbyImageData}
              alt={props.attributes?.mediaAlt}
              ratio={getImageRatioByNumber(props.numberOfImageType || 0)}
            />
          </InViewElement>
        </div>
        <div className="media-text__text">
          {props.innerBlocks && props.innerBlocks.map((block: any,index) => {
                  const blockName: string = block.name.replace('core/', '');

                  switch (blockName) {
                      case 'paragraph':
                        return <ParagraphBlock {...block} key={index} />;
                      case 'heading':
                        return <HeadingBlock {...block} key={index} />;
                      case 'list':
                        return <ListBlock {...block} key={index} />;
                      case 'quote':
                        return <QuoteBlock {...block} key={index} />;
                      case 'pullquote':
                        return <PullQuoteBlock {...block} key={index} />;
                      case 'verse':
                        return <VerseBlock {...block} key={index} />;
                      case 'buttons':
                        return <ButtonsBlock {...block} key={index} />;
                  }
              })}
          </div>
    </StyledMediaTextBlock>
  );
};


export const MediaTextBlockFragment = graphql`
  fragment MediaTextBlockFragment on WpCoreMediaTextBlock {
    order
    attributes {
      ... on WpCoreMediaTextBlockAttributes {
        href
        mediaId
        mediaLink
        mediaUrl
        rel
        mediaType
        linkTarget
        mediaAlt
        verticalAlignment
        ...BlockImageMediaTextFragment
      }
    }
    innerBlocks {
      name
      ...ParagraphBlockFragment
      ...HeadingFragment
      ...ListBlockFragment
      ...QuoteBlockFragment
      ...PullQuoteBlockFragment
      ...VerseBlockFragment
      ...ButtonsBlockFragment
    }
  }
`;
import styled from 'styled-components';
import { media } from '../../global/media.styles';
import { blockSpacing } from '../../global/spacing.styles';

export const StyledTeamMembers = styled.div`
    ${blockSpacing}
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 40px;
    column-gap: 30px;

    @media ${media.tablet} {
        grid-template-columns: 1fr 1fr;
    }

    @media ${media.tabletLandscape} {
        grid-template-columns: 1fr 1fr 1fr;
    }

`;

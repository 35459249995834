import * as React from 'react';
import { StyledReview } from './review.styles';
import { CMSContentPart } from './cms-content';
import { ThemeProvider } from "styled-components";
import FacebookIcon from "../svg/facebook-icon.svg";
import LinkedInIcon from "../svg/linkedin-icon.svg";
import GoogleIcon from "../svg/google-icon.svg";
import { ReviewFragmentFragment, Maybe } from "../../gatsby-graphql";
import { siteTheme } from '../global/theme.styles';
import { LinkPart } from './link';

export interface IReviewProps {
  review: Maybe<ReviewFragmentFragment>;
  reviews: Maybe<ReviewFragmentFragment>[];
  index?: number;
  isVisible?: boolean;
  large?: boolean;
}

const getIconFromSource = (source: Maybe<string>) => {
  switch(source) {
    case "Facebook":
        return <FacebookIcon />
    case "LinkedIn":
        return <LinkedInIcon />
    case "Google":
        return <GoogleIcon />
  }
}

export const Review: React.FC<IReviewProps> = props => {
  const reviewFields = props.review?.review;
  let icon: any | undefined = !!reviewFields?.reviewSource && getIconFromSource(reviewFields.reviewSource[0]);

  return (
    <ThemeProvider theme={siteTheme.secondaryComponentTheme}>
        <StyledReview isVisible={props.isVisible} large={props.large}>
            <div className="review__content">
                <CMSContentPart content={reviewFields?.review || ''} />
            </div>
            <div className="review__attribution">
                <cite>
                    {reviewFields?.reviewerName}{reviewFields?.reviewerTitle ? ` - ${reviewFields.reviewerTitle}` : ''}
                </cite>
                {reviewFields?.reviewSource &&
                    <span className="review__source">
                        {icon || `on ${reviewFields?.reviewSource}`}
                        {reviewFields.reviewLink && <LinkPart to={reviewFields.reviewLink} />}
                    </span>
                }
            </div>
        </StyledReview>
    </ThemeProvider>
  );
};

export default Review;
import * as React from 'react';
// import { StyledEmbed } from './embed.styles';
import { graphql } from 'gatsby';
import { YoutubeVideo } from '../../parts/embeds/youtube-video';
import { EmbedBlockFragmentFragment } from '../../../gatsby-graphql';
import { BaseBlockProps } from '../blocks';

export interface IEmbedBlockProps extends EmbedBlockFragmentFragment, BaseBlockProps {};

export const EmbedBlock: React.FC<IEmbedBlockProps> = props => {

    return (
        <div className={`embed embed--${props.attributes?.providerNameSlug}`}>
            {(props.attributes?.providerNameSlug == 'youtube') &&
                <YoutubeVideo url={props.attributes.url || undefined} />
            }
            <span>{props.attributes?.caption}</span>
        </div>
    );
};

export const EmbedBlockFragment = graphql`
    fragment EmbedBlockFragment on WpCoreEmbedBlock {
        attributes {
            ... on WpCoreEmbedBlockAttributes {
                caption
                type
                url
                providerNameSlug
            }
        }
    }
`;

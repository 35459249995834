import styled from 'styled-components';
import { ReviewsInView, StyledReview } from './review.styles';

export interface IStyledReviewsProps {
    inView?: boolean;
    reviewInView?: number;
}

export const StyledReviews = styled.div<IStyledReviewsProps>`
    display: grid;
    grid-template-rows: 1fr;

    & ${StyledReview} {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
        height: fit-content;
        align-self: center;

        &:nth-of-type(${p => p.reviewInView && p.reviewInView}) {
            ${ReviewsInView({isVisible: true, swap: true})}
        }
    }
`;

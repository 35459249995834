import styled from 'styled-components';
import { media } from '../../global/media.styles';
import { facebookIconLightStyles, linkedinIconLightStyles, googleIconLightStyles, facebookIconDarkStyles, googleIconDarkStyles, linkedinIconDarkStyles } from '../../global/icon.styles';
import { blockSpacing } from '../../global/spacing.styles';
import { colors } from '../../global/theme.styles';

const handleSVGStyles = (style: string | undefined) => {
    switch (style) {
        case "google-light":
            return googleIconLightStyles;
        case "google-dark":
            return googleIconDarkStyles;
        case "facebook-light":
            return facebookIconLightStyles;
        case "facebook-dark":
            return facebookIconDarkStyles;
        case "linkedIn-light":
            return linkedinIconLightStyles;
        case "linkedIn-dark":
            return linkedinIconDarkStyles;
        default:
            return;
    }
};

export const StyledSocialLinks = styled.div`
    ${blockSpacing}
    display: inline-block;
    text-align: center;

    @media ${media.tablet} {
        text-align: left;
    }

    .social-links {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-column-gap: 20px;

        @media ${media.tablet} {
            grid-column-gap: 25px;
        }

        &__item {
            width: 25px;
            height: 25px;

            @media ${media.tablet} {
                width: 35px;
                height: 35px;
            }
        }
    }

    .facebook-icon,
    .linkedin-icon,
    .google-icon {
        width: 25px;
        height: 25px;
        transform: translate3d(0, 0, 0);

        @media ${media.tablet} {
            width: 35px;
            height: 35px;
        }
    }

    .facebook-icon {
        @media (prefers-color-scheme: light) {
            ${p => p.theme.textColor.light === colors.black? handleSVGStyles('facebook-dark') : handleSVGStyles('facebook-light')};
        }

        @media (prefers-color-scheme: dark) {
            ${p => p.theme.textColor.dark === colors.black ? handleSVGStyles('facebook-dark') : handleSVGStyles('facebook-light')};
        }
    }

    .linkedin-icon {
        @media (prefers-color-scheme: light) {
            ${p => p.theme.textColor.light === colors.black? handleSVGStyles('linkedIn-dark') : handleSVGStyles('linkedIn-light')};
        }

        @media (prefers-color-scheme: dark) {
            ${p => p.theme.textColor.dark === colors.black ? handleSVGStyles('linkedIn-dark') : handleSVGStyles('linkedIn-light')};
        }
    }

    .google-icon {
        @media (prefers-color-scheme: light) {
            ${p => p.theme.textColor.light === colors.black ? handleSVGStyles('google-dark') : handleSVGStyles('google-light')};
        }

        @media (prefers-color-scheme: dark) {
            ${p => p.theme.textColor.dark === colors.black ? handleSVGStyles('google-dark') : handleSVGStyles('google-light')};
        }
    }
`;
 
import styled from 'styled-components';
import { animation } from '../global/animation.styles';
import { media } from '../global/media.styles';
import { blockSpacing } from '../global/spacing.styles';
import { blur, colors, layer, opacity } from '../global/theme.styles';
import { bragParagraphStyles } from '../global/typography.styles';
import { StyledLinkButton } from './button.styles';
import { StyledInView } from './in-view-element.styles';

export interface IStyledCareerNavigationProps {
    isExpanded?: boolean;
}

export const StyledCareerNavigation = styled.div<IStyledCareerNavigationProps>`
        position: sticky;
        grid-column: 1/4;
        grid-row: 5/6;
        bottom: 0px;
        height: fit-content;
        width: 100%;
        background-color: ${colors.blackMediumOpacity};
        transform: translate3d(0 , 0, 0);
        backdrop-filter: ${`blur(${blur.base})`};
        z-index: ${layer.stickyHeader};

        @media ${media.tabletLandscape} {
            top: 90px;
            bottom: auto;
            grid-row: 1/2;
        }


    & ${StyledInView} {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        width: 100%;
        padding-bottom: 5px;

        @media ${media.tabletLandscape} {
            padding-bottom: 0;
        }

    }

    select {
        ${bragParagraphStyles}
        line-height: 60px;
        padding: 5px;
        padding-right: 70px;
        width: 100%;
        color: ${colors.white};
        background-color: ${colors.whiteMostOpacity};
        transition: color ${animation.hover}, background-color ${animation.hover}, opacity ${animation.hover};
        appearance: none;
        border: none;
        border-radius: 0;
        cursor: pointer;
        outline: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media ${media.tabletLandscape} {
            line-height: 75px;
        }

        &:hover {
            background-color: ${colors.whiteHighOpacity};

            + .arrow-icon {
                /* opacity: ${opacity.partial}; */
            }
        }

        option {
            background-color: ${colors.blackPartialOpacity};
            backdrop-filter: ${`blur(${blur.base})`};

            &:hover {
                background-color: ${colors.blackMediumOpacity};
            }
        }
    }

    & .career-navigation {
        &__select {
            ${blockSpacing}
            position: relative;
            grid-column: 1/3;
            max-width: 100%;
            overflow-x: hidden;

            @media ${media.tabletLandscape} {
                grid-column: 1/2;
            }

            .arrow-icon {
                position: absolute;
                top: 50%;
                right: 25px;
                height: 50px;
                transform: translate3d(-50%, -50%, 0) rotate(90deg);
                transform-origin: center;
                opacity: ${opacity.none};
                transition: opacity ${animation.hover} transform ${animation.hover};
                pointer-events: none;

                @media ${media.tablet} {
                    right: calc( 5vw + 20px);
                }

                .arrow-icon__shape {
                    stroke: ${colors.white};
                    fill: ${colors.white};
                }
            }
        }

        &__button {
            ${blockSpacing}
            width: 100%;

            grid-column: 1/3;
            z-index: ${layer.stickyHeader};

            @media ${media.tabletLandscape} {
                grid-column: 2/3;
            }
        }
    }

    ${StyledLinkButton} {
        max-width: none;

        &:before {
            background-color: ${colors.whiteMostOpacity};
        }

        &::after {
            background-color: ${colors.whiteMediumOpacity};
        }
    }
`;

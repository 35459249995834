import React from "react";
import { FileSystemNode } from "gatsby-source-filesystem";
import { ImagePart } from "./image";
import { StyledTeamMember } from "./team-member.styles";
import { InViewElement } from "./in-view-element";
import { Maybe, TeamMemberFragmentFragment } from '../../gatsby-graphql';


export interface ITeamMemberNode extends ITeamMemberProps, TeamMemberFragmentFragment {};

export interface ITeamMemberProps extends TeamMemberFragmentFragment {
    teamMembers?: Maybe<TeamMemberFragmentFragment>[];
}

const TeamMember: React.FC<ITeamMemberProps> = props => {

    const teamMemberFields = props.teamMember;

    return (
    <InViewElement>
        <StyledTeamMember>
            <div className="team-member__image">
                {props.featuredImage && 
                    <ImagePart
                        src={props.featuredImage.node?.src && props.featuredImage.node.src.childImageSharp?.gatsbyImageData}
                        ratio="stout"
                    />
                }
            </div>
            {teamMemberFields &&
                <div className="team-member__info">
                    {teamMemberFields.name && 
                        <div className="team-member__name">
                            {teamMemberFields.name}
                        </div>
                    }
                    {teamMemberFields.position && 
                        <div className="team-member__position">
                            {teamMemberFields.position}
                        </div>
                    }
                    {teamMemberFields.description && 
                        <div className="team-member__description">
                            {teamMemberFields.description}
                        </div>
                    }
                </div>
            }
        </StyledTeamMember>
    </InViewElement>
    );
}

export default TeamMember;
import * as React from 'react';
import { StyledHeading } from './heading.styles';
import { graphql } from 'gatsby';
import { CMSContentPart } from '../../parts/cms-content';
import { InViewElement } from '../../parts/in-view-element';
import { HeadingFragmentFragment } from '../../../gatsby-graphql';
import { BaseBlockProps } from '../blocks';

export interface IHeadingBlockProps extends HeadingFragmentFragment, BaseBlockProps {};

export const HeadingBlock: React.FC<IHeadingBlockProps> = props => {

    return (
    <StyledHeading id={props.attributes?.anchor || ''}>
        <InViewElement>
          <CMSContentPart content={props.attributes?.content || ''} />
        </InViewElement>
    </StyledHeading>
  );
};

export const HeadingFragment = graphql`
    fragment HeadingFragment on WpCoreHeadingBlock {
      attributes {
        ... on WpCoreHeadingBlockAttributes {
          align
          anchor
          content
          level
          placeholder
        }
      }
    }
`;

import * as React from 'react';
import { StyledQuote } from './quote.styles';
import { graphql } from 'gatsby';
import { InViewElement } from '../../parts/in-view-element';
import { ThemeProvider } from 'styled-components';
import { siteTheme } from '../../global/theme.styles';
import Review from '../../parts/review';
import { QuoteBlockFragmentFragment } from '../../../gatsby-graphql';
import { BaseBlockProps } from '../blocks';

export interface IQuoteBlockProps extends QuoteBlockFragmentFragment, BaseBlockProps { }

export const QuoteBlock: React.FC<IQuoteBlockProps> = props => {

  return (
    <ThemeProvider theme={siteTheme.secondaryComponentTheme}>
      <StyledQuote>
        <InViewElement>
          <Review review={{
            review: {
              review: props.quoteAttributes?.value,
              reviewerName: props.quoteAttributes?.citation
            },
            databaseId: 0,
          }}
            reviews={[]}
            large={props.quoteAttributes?.className === 'is-style-large'}
          />
        </InViewElement>
      </StyledQuote>
    </ThemeProvider>
  );
};

export const QuoteBlockAttributes = graphql`
fragment QuoteBlockAttributesFragment on WpCoreQuoteBlockAttributes {
      citation
      value
      className
    }
`;

export const QuoteBlockFragment = graphql`
    fragment QuoteBlockFragment on WpCoreQuoteBlock {
      quoteAttributes: attributes {
        ...QuoteBlockAttributesFragment
      }
    }
`;

import { useStaticQuery, graphql } from "gatsby";
import { ReviewsQueryQuery } from "../../gatsby-graphql";

export const useReviews = () => {
    const data = useStaticQuery<ReviewsQueryQuery>(graphql`
    query ReviewsQuery {
        allWpReview {
          nodes {
            ...ReviewFragment
          }
        }
      }`
    )
  return data.allWpReview
}

export const ReviewCustomFieldsFragment = graphql`
  fragment ReviewCustomFieldsFragment on WpReview_Review {
    review
    reviewLink
    reviewSource
    reviewerName
    reviewerTitle
  }
`;

export const ReviewFragment = graphql`
fragment ReviewFragment on WpReview {
  databaseId
  review {
    ...ReviewCustomFieldsFragment
  }
  categories {
    nodes {
      databaseId
    }
  }
}
`;

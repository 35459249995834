import * as React from 'react';
import { StyledImageBlock } from './image.styles';
import { ImagePart } from '../../parts/image';
import { graphql } from 'gatsby';
import { getImageRatioByNumber } from '../../helpers/image';
import { LinkPart } from '../../parts/link';
import { InViewElement } from '../../parts/in-view-element';
import { ImageBlockFragmentFragment } from '../../../gatsby-graphql';

export interface IImageBlockProps extends ImageBlockFragmentFragment {
  numberOfType?: number;
  numberOfImageType?: number;
  inColumn?: boolean;
}

export const ImageBlock: React.FC<IImageBlockProps> = props => {

  return (
    <StyledImageBlock inColumn={props.inColumn} hasText={!!props.attributes?.caption && props.attributes?.caption !== ""}>
      <InViewElement>
        {props.attributes?.href ?
            <LinkPart to={props.attributes.href || ""}>
              <ImagePart src={props.attributes?.src?.childImageSharp?.gatsbyImageData} caption={props.attributes?.caption || undefined} ratio={getImageRatioByNumber(props.numberOfImageType || 0)} />
            </LinkPart>
            : <ImagePart src={props.attributes?.src?.childImageSharp?.gatsbyImageData} caption={props.attributes?.caption || undefined} ratio={getImageRatioByNumber(props.numberOfImageType || 0)} />
        }
      </InViewElement>
    </StyledImageBlock>
  );
};

export const ImageBlockFragment = graphql`
    fragment ImageBlockFragment on WpCoreImageBlock {
      attributes {
        ... on WpCoreImageBlockAttributes {
          id
          href
          alt
          caption
          sizeSlug
          align
          ...BlockImageFragment
        }
      }
    }
`;

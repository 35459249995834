import styled from 'styled-components';
import { ReviewsInView, StyledReview } from '../../parts/review.styles';

export interface IStyledQuoteProps {
    large?: boolean;
}

export const StyledQuote = styled.div<IStyledQuoteProps>`
    .in-view--visible {
        & ${StyledReview} {
            ${ReviewsInView({isVisible: true})}
        }
    }
`;

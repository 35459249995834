import styled from 'styled-components';
import { h2Styles } from '../../global/typography.styles';
import { blockSpacing } from '../../global/spacing.styles';

export const StyledHeading = styled.h2`
    ${h2Styles}
    ${blockSpacing}
    overflow-wrap: anywhere;
    word-break: break-word;
`;

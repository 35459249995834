import * as React from 'react';
import { StyledQuote } from './quote.styles';
import { graphql } from 'gatsby';
import { siteTheme } from '../../global/theme.styles';
import { ThemeProvider } from 'styled-components';
import { InViewElement } from '../../parts/in-view-element';
import Review from '../../parts/review';
import { PullQuoteBlockFragmentFragment } from '../../../gatsby-graphql';
import { BaseBlockProps } from '../blocks';

export interface IPullQuoteBlockProps extends PullQuoteBlockFragmentFragment, BaseBlockProps {};

export const PullQuoteBlock: React.FC<IPullQuoteBlockProps> = props => {

  return (
    <ThemeProvider theme={siteTheme.secondaryComponentTheme}>
    <StyledQuote>
      <InViewElement>
        <Review review={{
            review: {
              review: props.pullQuoteAttributes?.value,
              reviewerName: props.pullQuoteAttributes?.citation
            },
            databaseId: 0,
          }}
          reviews={[]}
        />
      </InViewElement>
    </StyledQuote>
    </ThemeProvider>
  );
};

export const PullQuoteBlockAttributes = graphql`
fragment PullQuoteBlockAttributesFragment on WpCorePullquoteBlockAttributes {
      citation
      value
    }
`;

export const PullQuoteBlockFragment = graphql`
    fragment PullQuoteBlockFragment on WpCorePullquoteBlock {
      pullQuoteAttributes: attributes {
        ...PullQuoteBlockAttributesFragment
      }
    }
`;

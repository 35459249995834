import { useStaticQuery, graphql } from "gatsby";
import { TeamMembersQueryQuery } from "../../gatsby-graphql";

export const useTeamMembers = () => {
    const data = useStaticQuery<TeamMembersQueryQuery>(graphql`
    query TeamMembersQuery {
            allWpTeamMember {
                nodes {
                    ...TeamMemberFragment
                }
            }
      }`
    )
  return data.allWpTeamMember?.nodes
}

export const TeamMemberFragment = graphql`
    fragment TeamMemberFragment on WpTeamMember {
        databaseId
        title
        featuredImage {
            node {
                ...ImageFragment
            }
        }
        teamMember {
            name
            position
            description
        }
    }
`;
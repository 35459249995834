import React from "react";
import { StyledCareerNavigation } from "./career-navigation.styles";
import { Button } from "./button";
import { InViewElement } from "./in-view-element";
import ArrowIcon from '../svg/arrow.svg';
import { navigate, useLocation } from "@reach/router";
import { trackEvent } from "../helpers/analytics";
import { CareerFragmentFragment, Maybe } from "../../gatsby-graphql";
import { hashNavigate } from "../helpers/navigation";


export interface ICareerNavigationProps {
    careers?: Maybe<CareerFragmentFragment>[];
    currentCareer?: string;
    isExpanded?: boolean;
}

const CareerNavigation: React.FC<ICareerNavigationProps> = props => {
    const location = useLocation();

    const updateCareer = React.useCallback(
        (hash: string) => {
            const newHash = `#${hash}`;
            location.hash === newHash ? hashNavigate(hash) : navigate(newHash);
            trackEvent('career_navigation', {
                event_category: 'navigation',
                event_label: `Navigating to ${hash}`,
                value: hash
            });
        }, [location.hash]);

    return (
        <StyledCareerNavigation isExpanded={props.isExpanded}>
            <InViewElement>
                <div className="career-navigation__select">
                    <select onChange={(event) => updateCareer(event.target.value)} value={props.currentCareer}>
                        {props.careers && props.careers.map((career, index: number) => {
                            const careerTitle = career && career.slug ? career.slug : '';

                            return (career &&
                                <option value={careerTitle} key={index}>
                                    {career.title}
                                </option>
                            );
                        }
                        )}
                    </select>
                    <ArrowIcon />
                </div>
                <div className="career-navigation__button">
                    <Button
                        text="Apply"
                        url={`#apply/${props.currentCareer}`}
                    // url={'https://forms.office.com/Pages/ResponsePage.aspx?id=iHfvPdNBf0aOcpaOhYFWiIY38wg_pLBGodOn7-8zvmVURVE0OE9XREJFWURZNVZPRzJNWkI4RzI1RC4u&fbclid=IwAR0e2AOS-FLdN-SWQb-LITgvDnd128Hy4XLiv2UKsFYUKDQVMygaP2ji8GI'}
                    />
                </div>
            </InViewElement>
        </StyledCareerNavigation>

    );
}

export default CareerNavigation;
import styled from 'styled-components';
import { paragraphStyles, smallParagraphTextStyles, boldParagraphStyles } from '../../global/typography.styles';
import { blockSpacing } from '../../global/spacing.styles';
import { colors } from '../../global/theme.styles';
import { media } from '../../global/media.styles';

export const StyledTable = styled.table`
    ${blockSpacing}
    ${paragraphStyles}
    width: 100%;
    table-layout: fixed;
    border-collapse: separate;

    th {
        ${boldParagraphStyles}
    }

    tr {
    }

    td {
        padding: 10px;
        border-bottom: solid 4px ${colors.lightGray};

        @media ${media.tabletLandscape} {
            padding: 15px;
        }

        &:not(:first-child) {
            border-left: solid 4px ${colors.lightGray};
        }
    }

    tfoot {
        ${smallParagraphTextStyles}
    }
`;

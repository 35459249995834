import styled from 'styled-components';
import { h1Styles } from '../../global/typography.styles';
import { colors, layer } from '../../global/theme.styles';
import { blockSpacing, spacing } from '../../global/spacing.styles';
import { StyledImage } from '../../parts/image.styles';
import { media } from '../../global/media.styles';
import { StyledButtons } from './buttons.styles';
import { StyledLink } from '../../parts/link.styles';
import { ImageScrimStyle } from '../../global/placeholders';
import { animation } from '../../global/animation.styles';

export interface IStyledCoverProps {
    isFirstBlock?: boolean;
}

export const StyledCover = styled.div<IStyledCoverProps>`
    position: relative;
    height: ${p => p.isFirstBlock ? 'calc(85vh - 90px)' : ''};
    width: 100%;
    overflow: hidden;

    & .cover {
        &__overlay {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: ${colors.black};
            opacity: 0.5;
            z-index: 2;
        }

        &__content {
            ${blockSpacing}
            position: absolute;
            bottom: 0;
            padding-bottom: 10vh;
            height: auto;
            width: 100%;
            z-index: 3;

            @media ${media.tabletLandscape} {
                padding-bottom: 15vh;
            }

            /* &:hover {
                & + ${StyledImage} {
                    & .gatsby-image-wrapper {
                        transform: scale(1.1);
                        transform-origin: center;
                    }
                }
            } */

            h1 {
                ${h1Styles}
                text-align: center;
                color: white;
                padding-bottom: 5vh;

                @media ${media.mobileLandscape} {
                    margin-block-end: 0;
                }

                @media ${media.tablet} {
                    margin-block-end: 0.67em;
                }

                & + ${StyledButtons} {
                    margin-top: -5vh;
                }

                & ${StyledLink} {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;

                    a {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        z-index: ${layer.coverLink};
                    }
                }
            }

            & ${StyledButtons} {
                max-width: 1000px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    & ${StyledImage} {
            ${ImageScrimStyle({hidden: true})}
            height: 100%;
            width: 100%;
            overflow: hidden;

            & .gatsby-image-wrapper {
                transform: scale(1);
                transform-origin: center;
                transition: transform ${animation.hoverImageZoom};
            }
        }
`;

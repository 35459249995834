import * as React from 'react';
import { getYoutubeThumbnail, getYoutubeVideoId } from '../../helpers/embed';
import { ImagePart } from '../image';
import { LinkPart } from '../link';
import { StyledYoutubeVideo } from './youtube-video.styles';
import PlayButtonIcon from '../../svg/play-button-icon.svg';

export interface IYoutubeVideoProps {
  url?: string;
}

export const YoutubeVideo: React.FC<IYoutubeVideoProps> = props => {
    const videoId = getYoutubeVideoId(props.url);
    const thumbnail = getYoutubeThumbnail(videoId);

  return (
    <StyledYoutubeVideo>
        <div className="youtube-video__image-wrapper">
            <LinkPart to={`#video/youtube/${videoId}`} />
            <ImagePart url={thumbnail} ratio="double" />
            <div className="youtube-video__play-button">
              <PlayButtonIcon />
            </div>
        </div>
    </StyledYoutubeVideo>
  );
};

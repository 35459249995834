import * as React from 'react';
import { StyledColumn } from './column.styles';
import { graphql } from 'gatsby';
import { ButtonsBlock } from './buttons';
import { HeadingBlock } from './heading';
import { ParagraphBlock } from './paragraph';
import { ImageBlock } from './image';
import { ListBlock } from './list';
import { VideoBlock } from './video';
import { QuoteBlock } from './quote';
import { PullQuoteBlock } from './pullquote';
import { VerseBlock } from './verse';
import { EmbedBlock } from './embed';
import { SpacerBlock } from './spacer';
import { CoverBlock } from './cover';
import { ColumnFragmentFragment } from '../../../gatsby-graphql';
import { BaseBlockProps } from '../blocks';

export interface IColumnBlockProps extends ColumnFragmentFragment, BaseBlockProps {
    columnNumber: number;
}

export const ColumnBlock: React.FC<IColumnBlockProps> = props => {

    return (
    <StyledColumn>
        {props?.innerBlocks && props.innerBlocks.map((block: any,index) => {
            const blockName: string = block.name.replace('core/', '');

            switch (blockName) {
                case 'paragraph':
                    return <ParagraphBlock {...block} key={index} />;
                case 'heading':
                    return <HeadingBlock {...block} key={index} />;
                case 'image':
                    return <ImageBlock {...block} inColumn={true} key={index} />;
                case 'list':
                    return <ListBlock {...block} key={index} />;
                case 'buttons':
                    return <ButtonsBlock {...block} key={index} />;
                case 'video':
                    return <VideoBlock {...block} key={index} />;
                case 'quote':
                    return <QuoteBlock {...block} key={index} />;
                case 'pullquote':
                    return <PullQuoteBlock {...block} key={index} />;
                case 'verse':
                    return <VerseBlock {...block} key={index} />;
                case 'embed':
                    return <EmbedBlock {...block} key={index} />;
                case 'spacer':
                    return <SpacerBlock {...block} key={index} />;
                case 'cover':
                    return <CoverBlock {...block} imageRatio="tall" key={index} />;
            }
        })}
    </StyledColumn>
  );
};

export const ColumnFragment = graphql`
    fragment ColumnFragment on WpCoreColumnBlock {
        attributes {
            ... on WpCoreColumnBlockAttributes {
                verticalAlignment
                width
            }
        }
        innerBlocks {
            __typename
            name
            ...ParagraphBlockFragment
            ...HeadingFragment
            ...ImageBlockFragment
            ...ListBlockFragment
            ...ButtonsBlockFragment
            ...VideoBlockFragment
            ...QuoteBlockFragment
            ...PullQuoteBlockFragment
            ...VerseBlockFragment
            ...EmbedBlockFragment
            ...SpacerBlockFragment
            ...CoverFragment
        }
    }
`;

import styled from 'styled-components';
import { blockSpacing, spacing } from '../../global/spacing.styles';
import { StyledImage } from '../../parts/image.styles';
import { StyledParagraph } from './paragraph.styles';
import { media } from '../../global/media.styles';

export interface IStyledMediaTextProps {
    reversed: boolean;
    verticalAlign?: string;
}

const handleAlignment = (alignment: string | undefined) => {
    switch (alignment) {
        case "center":
            return "center";
        case "top":
            return "start";
        case "bottom":
            return "end";
        default:
            return "";
    }
};

export const StyledMediaTextBlock = styled.div<IStyledMediaTextProps>`
    display: grid;

    @media ${media.tablet} {
        ${blockSpacing}
        grid-template-columns: 1fr 1fr;
        grid-auto-flow: dense;
    }

    ${StyledImage} {
        grid-column: 1 / 1;

        @media ${media.tablet} {
            grid-column: ${p => p.reversed ? '2 / 2' : '1 / 2' };
            align-self: center;
        }
    }

    .media-text {
        &__image {
            grid-column: 1 / 1;
            padding-bottom: ${spacing.blockPaddingY};

            @media ${media.tablet} {
                padding-bottom: 0;
                grid-column: ${p => p.reversed ? '2 / 2' : '1 / 2' };
                align-self: ${p => handleAlignment(p.verticalAlign)};
            }
        }

        &__text {
            grid-column: 1 / 1;
            align-self: ${p => handleAlignment(p.verticalAlign)};


            @media ${media.tablet} {
                grid-column: ${p => p.reversed ? '1 / 2' : '2 / 2' };
            }
        }
    }
`;

import styled from 'styled-components';
import { paragraphStyles, LinkStyles, smallParagraphStyles, bragParagraphStyles, HugeParagraphStyles, largeParagraphStyles } from '../../global/typography.styles';
import { blockSpacing } from '../../global/spacing.styles';

export interface IStyledParagraphProps {
    paragraphAlign?: string;
    paragraphFontSize?: string;
    dropCap?: boolean;
}

const handleFontSize = (size: string | undefined) => {
    switch (size) {
        case "small":
            return smallParagraphStyles;
        case "large":
            return largeParagraphStyles;
        case "larger":
            return bragParagraphStyles;
        default:
            return paragraphStyles;
    }
};

export const StyledParagraph = styled.p<IStyledParagraphProps>`
    ${p => handleFontSize(p.paragraphFontSize)}
    ${blockSpacing}
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    text-align: ${p => p.paragraphAlign || 'initial'};
    overflow-wrap: anywhere;
    word-break: break-word;

    a {
        ${LinkStyles}
    }
`;

import * as React from 'react';
import { StyledSpacer } from './spacer.styles';
import { graphql } from 'gatsby';
import { SpacerBlockFragmentFragment } from '../../../gatsby-graphql';
import { BaseBlockProps } from '../blocks';

export interface ISpacerBlockProps extends SpacerBlockFragmentFragment, BaseBlockProps {};

export const SpacerBlock: React.FC<ISpacerBlockProps> = () => {

  return (
    <StyledSpacer>
    </StyledSpacer>
  );
};

export const SpacerBlockFragment = graphql`
    fragment SpacerBlockFragment on WpCoreSpacerBlock {
        attributes {
            ... on WpCoreSpacerBlockAttributes {
                height
            }
        }
    }
`;

import * as React from 'react';
import { StyledCover } from './cover.styles';
import { ImagePart, ImageRatios } from '../../parts/image';
import { graphql } from 'gatsby';
import { ButtonsBlock } from './buttons';
import { CMSContentPart } from '../../parts/cms-content';
import { CoverFragmentFragment } from '../../../gatsby-graphql';

export interface ICoverBlockProps extends CoverFragmentFragment {
    isFirstBlock?: boolean;
    imageRatio?: ImageRatios;
}

export const CoverBlock: React.FC<ICoverBlockProps> = props => {

    return (
    <StyledCover isFirstBlock={props.isFirstBlock}>
        <div className="cover__overlay" />
        {props.isFirstBlock ?
            <>
            <ImagePart 
                src={props.attributes?.src?.childImageSharp?.gatsbyImageData} 
                priority="eager" 
                height="calc(85vh - 90px)" 
            />
            <div className="cover__content">
            {props.innerBlocks && props.innerBlocks.map((block: any,index: number) => {
                const blockName: string = block.name.replace('core/', '');
    
                switch (blockName) {
                    case 'paragraph':
                        return (<h1 key={index}><CMSContentPart content={block.attributes?.content || ''} /></h1>);
                    case 'heading':
                        return (<h1 key={index}><CMSContentPart content={block.attributes?.content || ''} /></h1>);
                    case 'buttons':
                        return <ButtonsBlock {...block} key={index} />
                }
            })}
            </div>
            </>
        :
            <>
            <div className="cover__content">
            {props.innerBlocks && props.innerBlocks.map((block: any,index: number) => {
                const blockName: string = block.name.replace('core/', '');

                switch (blockName) {
                    case 'paragraph':
                        return (<h1 key={index}><CMSContentPart content={block.attributes?.content || ''} /></h1>);
                    case 'heading':
                        return (<h1 key={index}><CMSContentPart content={block.attributes?.content || ''} /></h1>);
                }
            })}
            </div>
            <ImagePart 
                src={props.attributes?.src?.childImageSharp?.gatsbyImageData} 
                priority="eager" 
                ratio={props.imageRatio || 'double'}
            />
            </>
        }
    </StyledCover>
  );
};

export const CoverFragment = graphql`
    fragment CoverFragment on WpCoreCoverBlock {
        attributes {
            ... on WpCoreCoverBlockAttributes {
              id
              url
              backgroundType
              align
              minHeight
              overlayColor
              ...BlockCoverImageFragment
            }
        }
        innerBlocks {
            __typename
            name
            ...ParagraphBlockFragment
            ...ButtonsBlockFragment
            ...HeadingFragment
        }
    }
`;

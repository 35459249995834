import React from 'react';
import { CoverBlock } from './blocks/cover';
import { ParagraphBlock } from './blocks/paragraph';
import { ListBlock } from './blocks/list';
import { graphql } from 'gatsby';
import { StyledBlocks } from './blocks.styles';
import { HeadingBlock } from './blocks/heading';
import { ImageBlock } from './blocks/image';
import { GalleryBlock } from './blocks/gallery';
import { QuoteBlock } from './blocks/quote';
import { LatestPostsBlock } from './blocks/latest-posts';
import { ButtonsBlock } from './blocks/buttons';
import { MediaTextBlock } from './blocks/media-text';
import { PullQuoteBlock } from './blocks/pullquote';
import { VerseBlock } from './blocks/verse';
import { FileBlock } from './blocks/file';
import { EmbedBlock } from './blocks/embed';
import { TableBlock } from './blocks/table';
import { SpacerBlock } from './blocks/spacer';
import { VideoBlock } from './blocks/video';
import { ColumnsBlock } from './blocks/columns';
import { CareersBlock } from './blocks/careers';
import { TeamMembersBlock } from './blocks/team-members';
import { ThemeProvider } from 'styled-components';
import { ReviewsBlock } from './blocks/reviews';
import { AutoReviewsBlock } from './blocks/auto-reviews';
import { SocialLinksBlock } from './blocks/social-links';
import { BlocksFragmentFragment } from '../../gatsby-graphql';
import { GenericBlock } from './blocks/generic-raw';

export interface IBlock<TBlockType extends string> {
  __typename: TBlockType;
}

export type VariableImageWidgetTypes = 'image' | 'media-text';

// export type BlockProps<
//   TBlockType extends string,
//   TComponent extends React.ComponentType<any>
// > = IBlock<TBlockType> & React.ComponentPropsWithoutRef<TComponent>;
export type BaseBlockProps = {
  isFirstBlock?: boolean;
  numberOfType?: number;
  numberOfImageType?: number;
}

export type BlockDefinition = BlocksFragmentFragment & BaseBlockProps;
export type BlockProps = Omit<BlockDefinition, "__typename">;

export const Block: React.FC<BlockDefinition> = props => {

  switch (props.__typename) {
    case 'WpCoreCoverBlock':
      return <CoverBlock {...props} />;
    case 'WpCoreHeadingBlock':
      return <HeadingBlock {...props} />;
    case 'WpCoreListBlock':
      return <ListBlock {...props} />;
    case 'WpCoreParagraphBlock':
      return <ParagraphBlock {...props} />;
    case 'WpCoreImageBlock':
      return <ImageBlock {...props} />;
    case 'WpCoreGalleryBlock':
      return <GalleryBlock {...props} />;
    case 'WpCoreQuoteBlock':
      return <QuoteBlock {...props} />;
    case 'WpCoreLatestPostsBlock':
      return <LatestPostsBlock {...props} />;
    case 'WpCoreButtonsBlock':
      return <ButtonsBlock {...props} />;
    case 'WpCoreMediaTextBlock':
      return <MediaTextBlock {...props} />;
    case 'WpCorePullquoteBlock':
      return <PullQuoteBlock {...props} />;
    case 'WpCoreVerseBlock':
      return <VerseBlock {...props} />;
    case 'WpCoreFileBlock':
      return <FileBlock {...props} />;
    case 'WpCoreEmbedBlock':
      return <EmbedBlock {...props} />;
    case 'WpCoreTableBlock':
      return <TableBlock {...props} />;
    case 'WpCoreSpacerBlock':
      return <SpacerBlock {...props} />;
    case 'WpCoreVideoBlock':
      return <VideoBlock {...props} />
    case 'WpCoreColumnsBlock':
      return <ColumnsBlock {...props} />
    case 'WpSafetyFirstPluginCareersBlock':
      return <CareersBlock {...props} />
    case 'WpSafetyFirstPluginTeamMembersBlock':
      return <TeamMembersBlock {...props} />
    case 'WpSafetyFirstPluginReviewsBlock':
      return <ReviewsBlock {...props} />
    case 'WpSafetyFirstPluginAutoReviewsBlock':
      return <AutoReviewsBlock {...props} />
    case 'WpCoreSocialLinksBlock':
      return <SocialLinksBlock {...props} />
  }

  return null;
};

export interface BlocksProps {
  blocks?: BlocksFragmentFragment[];
  theme?: any;
}

export const Blocks: React.FC<BlocksProps> = props => {
  let blockNameArray: string[] = [];

  return (
    <StyledBlocks className="blocks">
      {props.blocks && props.blocks.map((block, index) => {
        const blockName: string = block.__typename.replace('WpCore', '').replace('WpSafetyFirstPlugin', '');
        blockNameArray.push(blockName);

        return (
          <div
            className={`block block--${blockName}`}
            key={index}
          >
            <ThemeProvider theme={props.theme.primaryComponentTheme}>
              <Block {...block}
                isFirstBlock={index === 0}
                numberOfType={blockNameArray.filter(function (x) { return x === blockName; }).length}
                numberOfImageType={
                  (blockName == 'ImageBlock' || blockName == 'MediaTextBlock')
                    ? blockNameArray.filter(function (x) {
                      return x === 'ImageBlock' || x === 'MediaTextBlock';
                    }).length : 0
                }
              />
            </ThemeProvider>
          </div>
        )
      }
      )}
    </StyledBlocks>
  );
};

export const BlocksFragment = graphql`
    fragment BlocksFragment on WpBlock {
        __typename
        isDynamic
        originalContent
        ...CoverFragment
        ...ImageBlockFragment
        ...ImageGalleryBlockFragment
        ...ButtonsBlockFragment
        ...MediaTextBlockFragment
        ...ParagraphBlockFragment
        ...ListBlockFragment
        ...HeadingFragment
        ...QuoteBlockFragment
        ...LatestPostsBlockFragment
        ...PullQuoteBlockFragment
        ...VerseBlockFragment
        ...FileBlockFragment
        ...EmbedBlockFragment
        ...TableBlockFragment
        ...SpacerBlockFragment
        ...VideoBlockFragment
        ...ColumnsBlockFragment
        ...CareersBlockFragment
        ...TeamMembersBlockFragment
        ...ReviewsBlockFragment
        ...AutoReviewsBlockFragment
        ...SocialLinksBlockFragment
      }
`;

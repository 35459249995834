import * as React from 'react';
import { StyledList, StyledOrderedList } from './list.styles';
import { graphql } from 'gatsby';
import { CMSContentPart } from '../../parts/cms-content';
import { InViewElement } from '../../parts/in-view-element';
import { ListBlockFragmentFragment } from '../../../gatsby-graphql';
import { BaseBlockProps } from '../blocks';

export interface IListBlockProps extends ListBlockFragmentFragment, BaseBlockProps {};

export const ListBlock: React.FC<IListBlockProps> = props => {

    return (
      <>
      {props.attributes && props.attributes?.ordered ? 
        <StyledOrderedList>
          <InViewElement>
            <CMSContentPart content={props.attributes?.values || ''} />
          </InViewElement>
        </StyledOrderedList>
        :
        <StyledList>
          <InViewElement>
            <CMSContentPart content={props.attributes?.values || ''} />
          </InViewElement>
        </StyledList>
      }
      </>
  );
};


export const ListBlockFragment = graphql`
    fragment ListBlockFragment on WpCoreListBlock {
      attributes {
        ordered
        values
      }
    }
`;
import * as React from 'react';
import { StyledFile } from './file.styles';
import { graphql } from 'gatsby';
import { LinkPart } from '../../parts/link';
import { Button } from '../../parts/button';
import { FileBlockFragmentFragment } from '../../../gatsby-graphql';
import { BaseBlockProps } from '../blocks';

export interface IFileBlockProps extends FileBlockFragmentFragment, BaseBlockProps {};

export const FileBlock: React.FC<IFileBlockProps> = props => {

  return (
    <StyledFile>
        <LinkPart to={props.attributes?.href || ''}>{props.attributes?.fileName}</LinkPart>
        {props.attributes?.showDownloadButton && <Button url={props.attributes?.href || undefined} text={props.attributes.downloadButtonText || undefined} />}
    </StyledFile>
  );
};

export const FileBlockFragment = graphql`
    fragment FileBlockFragment on WpCoreFileBlock {
        attributes {
            ... on WpCoreFileBlockAttributes {
                href
                anchor
                textLinkTarget
                fileName
                downloadButtonText
                showDownloadButton
            }
        }
    }
`;

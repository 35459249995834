import * as React from 'react';
import { Button } from '../../parts/button';
import { graphql } from 'gatsby';
import { StyledButtons } from './buttons.styles';
import { ButtonsBlockFragmentFragment } from '../../../gatsby-graphql';
import { BaseBlockProps } from '../blocks';

export interface IButtonsBlockProps extends ButtonsBlockFragmentFragment, BaseBlockProps {}

export const ButtonsBlock: React.FC<IButtonsBlockProps> = props => {
    return (
      <StyledButtons buttonCount={props.innerBlocks?.length}>
      {props.innerBlocks?.map((button, index) => (
          <Button text={button.attributes?.text || undefined} url={button.attributes?.url || undefined} key={index} secondary={index > 0} />
      ))}
      </StyledButtons>
  );
};


export const ButtonsBlockFragment = graphql`
  fragment ButtonsBlockFragment on WpCoreButtonsBlock {
      innerBlocks {
        ... on WpCoreButtonBlock {
          attributes {
            ... on WpCoreButtonBlockAttributes {
              text
              title
              url
            }
          }
        }
      }
    }
`;
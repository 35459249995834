import styled from 'styled-components';

export const StyledColumn = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 45px;
    height: max-content;

    grid-column: span 1;
`;

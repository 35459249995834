import * as React from 'react';
import { StyledGalleryBlock } from './gallery.styles';
import { ImagePart, ImageRatios } from '../../parts/image';
import { graphql } from 'gatsby';
import { useGalleryContext } from '../../global/gallery.context';
import { GallerySlider } from '../../parts/gallery-slider';
import { LinkPart } from '../../parts/link';
import { useEffect } from 'react';
import { ImageGalleryBlockFragmentFragment } from '../../../gatsby-graphql';
import { BaseBlockProps } from '../blocks';

// Desktop gallery
// top left: 6:5
// top right: 10:11
// top right two bottom: 10:11
// bottom: 16:8 full width
// Add a button for full gallery slider

export interface IGalleryBlockProps extends ImageGalleryBlockFragmentFragment, BaseBlockProps {}

export const GalleryBlock: React.FC<IGalleryBlockProps> = props => {
    const { createGallery } = useGalleryContext();
    const [galleryID, setGalleryID] = React.useState<number | undefined>(undefined);

    useEffect(() => {
        (props.attributes?.columns && props.attributes?.columns > 1) && (props.attributes?.images?.length || 0) > 3 && createGallery && props.attributes?.images && setGalleryID(createGallery(props.attributes.images));
        
        return () => {
        };
    }, []);

    return (
        (props.attributes?.columns && props.attributes?.columns > 1) && (props.attributes?.images?.length || 0) > 3 ?
        <StyledGalleryBlock>
            {props.attributes?.images && props.attributes.images.map((image, index) => {
                if (index < 5) {
                    let ratio: ImageRatios = 'tall';

                    switch (index) {
                        case 0:
                            ratio = 'stout';
                            break;
                        case 4:
                            ratio = 'double';
                            break;
                    }
    
                    return (
                        <LinkPart to={`#image-gallery/${galleryID}/${index}`} key={index}>
                            <ImagePart src={image?.src?.childImageSharp?.gatsbyImageData} caption={image?.caption || undefined} ratio={ratio}/>
                        </LinkPart>
                    )
                }
            }
        )}
        </StyledGalleryBlock>
        :
        props.attributes?.images ? <GallerySlider images={props.attributes.images} hasSlideCount={true} /> : null
  );
};

export const ImageGalleryBlockImagesFragment = graphql`
    fragment ImageGalleryBlockImagesFragment on WpCoreGalleryBlockAttributesImages {
        id
        url
        fullUrl
        link
        caption
        alt
        ...BlockImageGalleryFragment
    }
`;


export const ImageGalleryBlockFragment = graphql`
    fragment ImageGalleryBlockFragment on WpCoreGalleryBlock {
        attributes {
            ... on WpCoreGalleryBlockAttributes {
                align
                caption
                images {
                    ...ImageGalleryBlockImagesFragment
                }
                columns
                ids
                linkTo
            }
        }
    }
`;

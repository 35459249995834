import styled from 'styled-components';
import { animation } from '../global/animation.styles';
import { SOLSVGStringWhite } from '../global/icon.styles';
import { media } from '../global/media.styles';
import { blockSpacing, spacing } from '../global/spacing.styles';
import { colors, layer, themedBackground, themedColor } from '../global/theme.styles';
import { h1Styles, LabelTextStyles, smallParagraphStyles } from '../global/typography.styles';
import { StyledList } from '../gutenberg/blocks/list.styles';
import { StyledButton } from './button.styles';

export interface IStyledCareerProps {
    isExpanded?: boolean;
}

export const StyledCareer = styled.div<IStyledCareerProps>`
    ${themedBackground};
    ${themedColor};
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-flow: dense;

    @media ${media.tabletLandscape} {
        /* position: ${p => p.isExpanded ? 'relative' : 'sticky'}; */
        /* top: 90px; */
        /* max-height: ${p => p.isExpanded ? '500vh' : 'calc(150vh - 90px)'}; */
        /* overflow-y: hidden; */
        /* overflow-y: ${p => p.isExpanded ? 'initial' : 'hidden'}; */
        /* z-index: ${p => p.isExpanded ? layer.stickyHeader : ''}; */
        transition: max-height ${animation.inView};
    }

    .career {

        &__title {
            ${h1Styles}
            ${blockSpacing}
            grid-column: 1/4;

            @media ${media.tabletLandscape} {
                display: none;
            }
        }

        &__blocks {
            grid-column: 1/4;
            height: 100%;
            padding-bottom: 25px;

            @media ${media.tabletLandscape} {
                grid-column: 2/4;
                grid-row: 2/6;
                /* max-height: ${p => p.isExpanded ? '500vh' : 'calc(150vh - 190px)'}; */
                /* overflow-y: hidden; */
                transition: max-height ${animation.inViewSecondary};
            }

            & ${StyledButton} {
            }
        }

        &__image {
            grid-column: 1/4;

            @media ${media.tabletLandscape} {
                padding: ${spacing.blockPaddingYDesktop}px 5vw ;
                padding-right: 0;
                grid-column: 1/2;
            }
        }

        &__info {
            ${smallParagraphStyles}
            ${blockSpacing}
            grid-column: 1/4;

            @media ${media.tabletLandscape} {
                position: sticky;
                top: 225px;
                padding: ${spacing.blockPaddingYDesktop}px 5vw ;
                padding-right: 0;
                grid-column: 1/2;
            }

            &-field {
                display: grid;
                grid-template-columns: 1fr 1fr;
                padding-bottom: 15px;
            }

            &-data {
                grid-column: 2/3;
                align-self: end;
                text-align: right;
            }

            & label {
                ${LabelTextStyles}
                grid-column: 1/2;
            }
        }

        &__expand-button {
            display: block;
            position: sticky;
            bottom: 0;
            padding-bottom: 25px;
            text-align: center;
            z-index: ${layer.careerButton}
        }
    }
`;

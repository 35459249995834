import styled from 'styled-components';
import { h3Styles, LinkStyles } from '../../global/typography.styles';
import { blockSpacing } from '../../global/spacing.styles';
import { media } from '../../global/media.styles';
import { StyledLink } from '../../parts/link.styles';
import { StyledButton, StyledLinkButton } from '../../parts/button.styles';
import { layer } from '../../global/theme.styles';

export const StyledFile = styled.div`
    ${blockSpacing}
    display: grid;
    grid-template-columns: 1fr 1fr;

    a {
        ${LinkStyles}
    }

    & ${StyledLink} {
        grid-column: 1 / 1;

        &:first-of-type {
            ${h3Styles}

        @media ${media.tablet} {
                grid-column: 1 / 2;
                align-self: baseline;
            }
        }

        &:nth-of-type(2) {
            @media ${media.tablet} {
                grid-column: 2 / 2;
                align-self: baseline;
            }
        }
    }

    & ${StyledLinkButton} {
        z-index: ${layer.baseLayer};
    }
`;

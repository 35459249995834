import styled from 'styled-components';
import { animation } from '../../global/animation.styles';
import { media } from '../../global/media.styles';
import { ImageScrimHoverStyle, ImageScrimStyle } from '../../global/placeholders';
import { layer, colors, blur } from '../../global/theme.styles';
import { StyledImage } from '../image.styles';
import { StyledLink } from '../link.styles';

export const StyledYoutubeVideo = styled.div`

    .youtube-video {

        &__image-wrapper {
            position: relative;
            overflow: hidden;

            @media ${media.tablet} {
                max-width: 80vw;
                margin-right: auto;
                margin-left: auto;
            }

            &:hover {
                & .youtube-video {

                    &__play-button {
                        background-color: ${colors.whiteMediumOpacity};
                    }
                }

                & ${StyledImage} {
                    ${ImageScrimHoverStyle({lighten: true})};
                    transform: scale(1.1);
                    transform-origin: center;
                }

            }
        }

        &__play-button {
            position: absolute;
            top: calc(50% - 45px);
            left: calc(50% - 45px);
            height: 90px;
            width: 90px;
            background-color: ${colors.whitePartialOpacity};
            border-radius: 50%;
            backdrop-filter: ${`blur(${blur.subtle})`};
            transition: background-color ${animation.hoverSecondary};

            @media ${media.tabletLandscape} {
                top: calc(50% - 65px);
                left: calc(50% - 65px);
                height: 130px;
                width: 130px;
            }

            svg {
                position: relative;
                height: 40px;
                width: 40px;
                top: 25px;
                left: 28px;

                @media ${media.tabletLandscape} {
                    height: 70px;
                    width: 70px;
                    top: 30px;
                    left: 40px;
                }
            }
        }
    }

    ${StyledImage} {
        ${ImageScrimStyle}
        width: 100%;
        transform: scale(1);
        transform-origin: center;
        transition: transform ${animation.hoverImageZoom};
    }

    ${StyledLink} a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: ${layer.youtubeVideoLink};
    }

    iframe {
        display: none;
    }
`;

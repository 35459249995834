import * as React from 'react';
import { graphql } from 'gatsby';
import { AutoReviewsBlockFragmentFragment } from '../../../gatsby-graphql';
import { Reviews } from '../../parts/reviews';
import { BaseBlockProps } from '../blocks';

export interface IAutoReviewsBlockProps extends AutoReviewsBlockFragmentFragment, BaseBlockProps {};

export const AutoReviewsBlock: React.FC<IAutoReviewsBlockProps> = props => {
    const reviewCategory = props.attributes?.reviewCategory;

    return (
      <Reviews category={reviewCategory} />
    );
};

export const AutoReviewsBlockFragment = graphql`
    fragment AutoReviewsBlockFragment on WpSafetyFirstPluginAutoReviewsBlock {
        attributes {
            reviewCategory
        }
    }
`;

import * as React from 'react';
import { StyledVerse } from './verse.styles';
import { graphql } from 'gatsby';
import { CMSContentPart } from '../../parts/cms-content';
import { VerseBlockFragmentFragment } from '../../../gatsby-graphql';
import { BaseBlockProps } from '../blocks';

export interface IVerseBlockProps extends VerseBlockFragmentFragment, BaseBlockProps {}

export const VerseBlock: React.FC<IVerseBlockProps> = props => {

  return (
    <StyledVerse>
      <CMSContentPart content={props.attributes?.content || ''} />
    </StyledVerse>
  );
};

export const VerseBlockFragment = graphql`
    fragment VerseBlockFragment on WpCoreVerseBlock {
        attributes {
            ... on WpCoreVerseBlockAttributes {
                content
            }
        }
    }
`;

import * as React from 'react';
import { graphql } from 'gatsby';
import { ReviewBlockFragmentFragment, ReviewsBlockFragmentFragment } from '../../../gatsby-graphql';
import { Reviews } from '../../parts/reviews';
import { BaseBlockProps } from '../blocks';

export interface IReviewsBlockProps extends ReviewsBlockFragmentFragment, BaseBlockProps {};

export const ReviewsBlock: React.FC<IReviewsBlockProps> = props => {
  const reviewIds = props.innerBlocks && props.innerBlocks.map((review: ReviewBlockFragmentFragment) => {
      return review.attributes?.reviewId;
  });

  return (
    reviewIds ? <Reviews reviewIds={reviewIds} sorted={true} /> : null
  )

};

export const ReviewBlockFragment = graphql`
fragment ReviewBlockFragment on WpSafetyFirstPluginReviewBlock {
  attributes {
    reviewId
  }
}
`;

export const ReviewsBlockFragment = graphql`
    fragment ReviewsBlockFragment on WpSafetyFirstPluginReviewsBlock {
      innerBlocks {
        ...ReviewBlockFragment
      }
    }
`;

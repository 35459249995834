import * as React from 'react';
import { StyledTable } from './table.styles';
import { graphql } from 'gatsby';
import { CMSContentPart } from '../../parts/cms-content';
import { TableBlockFragmentFragment } from '../../../gatsby-graphql';
import { BaseBlockProps } from '../blocks';

export interface ITableBlockProps extends TableBlockFragmentFragment, BaseBlockProps {};

export const TableBlock: React.FC<ITableBlockProps> = props => {
  return (
    <StyledTable>
        {props.attributes?.head && 
        <thead>
            {props.attributes.head.map((row, index) => 
                <tr key={index}>
                    {row?.cells?.map((cell, index) => {
                        return (
                            <th key={index}><CMSContentPart content={cell?.content || ''}></CMSContentPart></th>
                        )
                        })
                    }
                </tr>
            )}
        </thead>
        }
        {props.attributes?.body && 
        <tbody>
            {props.attributes.body.map((row, index) => 
                <tr key={index}>
                    {row?.cells?.map((cell, index) => {
                        return (
                            <td key={index}><CMSContentPart content={cell?.content || ''}></CMSContentPart></td>
                        )
                        })
                    }
                </tr>
            )}
        </tbody>
        }
        {props.attributes?.foot && 
        <tfoot>
            {props.attributes.foot.map((row, index) => 
                <tr key={index}>
                    {row?.cells?.map((cell, index) => {
                        return (
                            <td key={index}><CMSContentPart content={cell?.content || ''}></CMSContentPart></td>
                        )
                        })
                    }
                </tr>
            )}
        </tfoot>
        }
    </StyledTable>
  );
};

export const TableBlockFragment = graphql`
    fragment TableBlockFragment on WpCoreTableBlock {
        attributes {
            ... on WpCoreTableBlockAttributes {
                body {
                    cells {
                        content
                    }
                }
                head {
                    cells {
                        content
                    }
                }
                foot {
                    cells {
                        content
                    }
                }
            }
        }
    }
`;

import styled from 'styled-components';
import { StyledImage } from '../../parts/image.styles';
import { media } from '../../global/media.styles';
import { colors } from '../../global/theme.styles';
import { animation } from '../../global/animation.styles';
import { StyledLink } from '../../parts/link.styles';
import { ImageScrimHoverStyle, ImageScrimStyle } from '../../global/placeholders';

export interface IStyledGalleryBlock {
    hasCaption?: boolean;
}

export const StyledGalleryBlock = styled.div<IStyledGalleryBlock>`
    display: grid;
    column-gap: 2px;
    row-gap: 2px;


    & ${StyledLink} {
        display: inline-block;
        position: relative;
        overflow: hidden;

        &:hover {
            & ${StyledImage} {
                ${ImageScrimHoverStyle({lighten: false})};

                & .gatsby-image-wrapper {
                    transform: scale(1.1);
                    transform-origin: center;
                }
            }

            & figcaption {
                opacity: 1;
                transform: translate3d(0, 0, 0);
            }
        }

        & ${StyledImage} {
            ${ImageScrimStyle({hidden: true})}
            height: 100%;
            width: 100%;

            & .gatsby-image-wrapper {
                transform: scale(1);
                transform-origin: center;
                transition: transform ${animation.hoverImageZoom};
            }
        }

        & figcaption {
            position: absolute;
            bottom: 0;
            left: 0;
            max-height: 100%;
            color: ${colors.white};
            opacity: 0;
            transform: translate3d(0, 15px, 0);
            transition: opacity ${animation.hover}, transform ${animation.hoverSecondary};
        }

        &:nth-child(5n+1) {
            grid-column: 1 / 3;

            @media ${media.tablet} {
                grid-column: 1 / 14;
                grid-row: 1 / 3;
            }
        }

        &:nth-child(5n+2) {
            grid-column: 1 / 3;

            @media ${media.tablet} {
                grid-column: 14 / 20;
            }

        }

        &:nth-child(5n+3) {
            grid-column: 1;

            @media ${media.tablet} {
                grid-column: 14 / 17;
            }
        }

        &:nth-child(5n+4) {
            grid-column: 2;

            @media ${media.tablet} {
                grid-column: 17 / 20;
            }
        }

        &:nth-child(5n+5) {
            grid-column: 1 / 3;

            @media ${media.tablet} {
                grid-column: 1 / 20;
            }
        }

    }
`;

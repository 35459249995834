import styled, { css } from 'styled-components';
import { bragParagraphStyles, paragraphStyles, quoteStyles, smallParagraphTextStyles } from '../global/typography.styles';
import { blockSpacing, spacing } from '../global/spacing.styles';
import { colors, layer, opacity, themedBackground } from '../global/theme.styles';
import { animation, transitionTiming } from '../global/animation.styles';
import { facebookIconDarkStyles, facebookIconLightStyles, googleIconDarkStyles, googleIconLightStyles, linkedinIconDarkStyles, linkedinIconLightStyles } from '../global/icon.styles';
import { StyledLink } from './link.styles';
import { media } from '../global/media.styles';
import { font } from '../global/fonts.styles';

export interface IStyledReviewProps {
    large?: boolean;
    isVisible?: boolean;
}

export interface IReviewStylesProps {
    isVisible?: boolean;
    swap?: boolean;
}

export const ReviewsInView = (props: IReviewStylesProps) => {
    return css`
        opacity: ${props.isVisible ? opacity.none : opacity.full};
        transition-delay: ${props.swap && props.isVisible ? `${transitionTiming('inView', 1.2)}` : ''};
        pointer-events: ${props.isVisible ? `all` : 'none'};

        &::before {
            transform: ${props.isVisible ? 'scaleY(1)' : 'scaleY(0)'};
            transition-delay: ${props.swap && props.isVisible ? `${transitionTiming('inView', 1.2)}` : ''};
        }

        & .review {
            &__content {
                opacity: ${props.isVisible ? opacity.none : opacity.full};
                transform: ${props.isVisible ? 'translate3d(0, 0, 0)' : 'translate3d(25px, 0, 0)'};
                transition-delay: ${props.swap && props.isVisible ? `${transitionTiming('inView', 1.2)}` : ''};
            }
        }
    `;
}

export const StyledReview = styled.blockquote<IStyledReviewProps>`
    ${blockSpacing}
    ${p => ReviewsInView({isVisible: p.isVisible})}
    position: relative;
    margin-block-start: ${spacing.blockPaddingY};
    margin-block-end: ${spacing.blockPaddingY};
    margin-inline-start: 25px;
    margin-inline-end: 25px;
    transition: opacity ${animation.inView};
    z-index: ${layer.baseLayer};

    @media ${media.tablet} {
        margin-block-start: ${spacing.blockPaddingYDesktop};
        margin-block-end: ${spacing.blockPaddingYDesktop};
        margin-inline-start: 40px;
        margin-inline-end: 40px;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 5px;
        height: 100%;
        background: ${colors.brandBlue};
        transform-origin: top;
        z-index: 1;
        transition: transform ${animation.inViewSecondary};
    }

    &::after {
        ${themedBackground}
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 70%;
        height: 100%;
        z-index: -1;
    }

    p {
        display: inline;
        margin: 0;
    }

    .review {
        &__content {
            ${p => p.large ? bragParagraphStyles : quoteStyles}
            display: inline-block;
            transition: opacity ${animation.inView}, transform ${animation.inViewSecondary};
            padding-top: 1em;
            padding-bottom: 1em;

            &:before {
                content: '“';
                display: inline-block;
            }

            &:after {
                content: '”';
                display: inline-block;
            }
        }

        &__attribution {
            ${p => p.large ? paragraphStyles : smallParagraphTextStyles}
            display: grid;
            grid-template-columns: auto auto;
            max-width: 70%;

            @media ${media.desktop} {
                font-size: ${font.size.title};
            }
        }

        &__source {
            position: relative;
            margin: 0 0.5em;
            justify-self: right;

            & ${StyledLink} a {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }

            .facebook-icon,
            .linkedin-icon,
            .google-icon {
                width: 25px;
                height: 25px;
                transform: translate3d(0, 0, 0);
            }

            .facebook-icon {
                @media (prefers-color-scheme: light) {
                    ${facebookIconDarkStyles}
                }

                @media (prefers-color-scheme: dark) {
                    ${facebookIconLightStyles}
                }
            }

            .linkedin-icon {
                @media (prefers-color-scheme: light) {
                    ${linkedinIconDarkStyles}
                }

                @media (prefers-color-scheme: dark) {
                    ${linkedinIconLightStyles}
                }
            }

            .google-icon {
                @media (prefers-color-scheme: light) {
                    ${googleIconDarkStyles}
                }

                @media (prefers-color-scheme: dark) {
                    ${googleIconLightStyles}
                }
            }
        }
    }
`;

import styled from 'styled-components';
import { paragraphStyles } from '../../global/typography.styles';
import { blockSpacing } from '../../global/spacing.styles';
import { font } from '../../global/fonts.styles';

export const StyledVerse = styled.div`
    ${blockSpacing}
    ${paragraphStyles}
    font-family: ${font.secondary};
`;

import * as React from 'react';
import { StyledColumns } from './columns.styles';
import { graphql } from 'gatsby';
import { ColumnBlock } from './column';
import { ColumnsBlockFragmentFragment } from '../../../gatsby-graphql';
import { BaseBlockProps } from '../blocks';

export interface IColumnsBlockProps extends ColumnsBlockFragmentFragment, BaseBlockProps {}

export const ColumnsBlock: React.FC<IColumnsBlockProps> = props => {

    return (
    <StyledColumns columnCount={props.innerBlocks?.length || 1}>
        {props.innerBlocks && props.innerBlocks.map((column,index) => {
            return <ColumnBlock {...column} columnNumber={index} key={index}/>
        })}
    </StyledColumns>
  );
};

export const ColumnsFragment = graphql`
    fragment ColumnsBlockFragment on WpCoreColumnsBlock {
        attributes {
            ... on WpCoreColumnsBlockAttributes {
                verticalAlignment
                align
            }
        }
        innerBlocks {
            ...ColumnFragment
        }
    }
`;

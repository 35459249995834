import styled from 'styled-components';
import { animation } from '../global/animation.styles';
import { media } from '../global/media.styles';
import { blockSpacing, spacing } from '../global/spacing.styles';
import { colors, layer } from '../global/theme.styles';
import { bragParagraphStyles, h1Styles, h3Styles, LabelTextStyles, smallParagraphStyles } from '../global/typography.styles';
import { StyledButton } from './button.styles';

export const StyledTeamMember = styled.div`
    text-align: center;

    .team-member {
        ${blockSpacing}

        &__info {
            ${blockSpacing}
        }

        &__name {
            ${bragParagraphStyles}
        }

        &__position {
            ${LabelTextStyles}
        }

        &__description {
            ${smallParagraphStyles}
        }
    }
`;

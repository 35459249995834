import * as React from 'react';
import { StyledLatestPosts } from './latest-posts.styles';
import { graphql } from 'gatsby';
import PostCard from '../../parts/post-card';
import { usePosts } from '../../hooks/posts.hook';
import { LatestPostsBlockFragmentFragment } from '../../../gatsby-graphql';
import { BaseBlockProps } from '../blocks';

export interface ILatestPostsBlockProps extends LatestPostsBlockFragmentFragment, BaseBlockProps {}

export const LatestPostsBlock: React.FC<ILatestPostsBlockProps> = () => {
  const posts = usePosts();

  return (
      <StyledLatestPosts>
          {posts?.nodes && posts.nodes.slice(0, 5).map((post, index: number) => (
              <PostCard {...post} key={index} />
          ))}
          <PostCard title="See More" />
      </StyledLatestPosts>
  );
};

export const LatestPostsBlockAttributes = graphql`
  fragment LatestPostsBlockAttributesFragment on WpCoreLatestPostsBlockAttributes {
      orderBy
  }
`;

export const LatestPostsBlockFragment = graphql`
    fragment LatestPostsBlockFragment on WpCoreLatestPostsBlock {
      attributes {
        ...LatestPostsBlockAttributesFragment
      }
    }
`;

import React, { useEffect } from "react";
import { ImagePart } from "./image";
import { StyledCareer } from "./career.styles";
import { InViewElement } from "./in-view-element";
import { ParagraphBlock } from "../gutenberg/blocks/paragraph";
import { HeadingBlock } from "../gutenberg/blocks/heading";
import { ImageBlock } from "../gutenberg/blocks/image";
import { ListBlock } from "../gutenberg/blocks/list";
import { ButtonsBlock } from "../gutenberg/blocks/buttons";
import { VideoBlock } from "../gutenberg/blocks/video";
import { QuoteBlock } from "../gutenberg/blocks/quote";
import { PullQuoteBlock } from "../gutenberg/blocks/pullquote";
import { VerseBlock } from "../gutenberg/blocks/verse";
import { EmbedBlock } from "../gutenberg/blocks/embed";
import { SpacerBlock } from "../gutenberg/blocks/spacer";
import CareerNavigation from "./career-navigation";
import { Button } from "./button";
import { ThemeProvider } from "styled-components";
import { isOdd } from "../helpers/operations";
import { CareerFragmentFragment, Maybe } from "../../gatsby-graphql";
import { siteTheme } from "../global/theme.styles";

export interface ICareerProps {
    career: Maybe<CareerFragmentFragment>;
    careers: Maybe<CareerFragmentFragment>[];
    observer?: IntersectionObserver | null;
    index?: number;
}

const Career: React.FC<ICareerProps> = props => {
    const careerElementRef = React.useRef<HTMLDivElement>(null);
    const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
    const careerName = props.career && props.career.slug ? props.career.slug : '';
    const careerFields = props.career?.career;

  return (
    <ThemeProvider theme={props.index && isOdd(props.index) ? siteTheme.secondaryComponentTheme : siteTheme.tertiaryComponentTheme}>
        <StyledCareer 
            id={careerName} 
            isExpanded={isExpanded} 
            ref={careerElementRef}
        >
            <CareerNavigation careers={props.careers} currentCareer={careerName} isExpanded={isExpanded} />
            <h2 className="career__title">
                <InViewElement>
                    {props.career && props.career.title}
                </InViewElement>
            </h2>
            <div className="career__image">
                {props.career?.featuredImage && 
                    <InViewElement>
                        <ImagePart src={props.career?.featuredImage.node?.src && props.career.featuredImage.node.src.childImageSharp?.gatsbyImageData} ratio="stout" />
                    </InViewElement>
                }
            </div>
            {careerFields &&
                <div className="career__info">
                    <InViewElement>
                    {careerFields?.location && 
                        <div className="career__info-field career__locations">
                            <label>
                                {`Location${careerFields.location.length > 1 ? 's' : ''}: `}
                            </label>
                            {careerFields.location.map((type, index) => 
                                <div className="career__info-data" key={index}>{type}</div>
                            )}
                        </div>
                    }
                    {careerFields.type && 
                        <div className="career__info-field career__type">
                            <label>Available Types: </label>
                            {careerFields.type.map((type, index) => 
                                <div className="career__info-data" key={index}>{type}</div>
                            )}
                        </div>
                    }
                    {careerFields.hourlyRateMin && 
                        <div className="career__info-field career__pay">
                            <label>Hourly Rate:</label>
                            <div className="career__info-data">
                            {careerFields.hourlyRateMin.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                })
                            }
                            {careerFields.hourlyRateMax && careerFields.hourlyRateMax > careerFields.hourlyRateMin && ` - ${careerFields.hourlyRateMax.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                })}`
                            }
                            {` per Hour`}
                            </div>
                        </div>
                    }
                    {careerFields.hoursPerWeekMin && 
                        <div className="career__info-field career__pay">
                            <label>Avaliable Hours:</label>
                            <div className="career__info-data">
                            {careerFields.hoursPerWeekMin}
                            {careerFields.hoursPerWeekMax && careerFields.hoursPerWeekMax > careerFields.hoursPerWeekMin && ` - ${careerFields.hoursPerWeekMax}`
                            }
                            {` Hours per Week`}
                            </div>
                        </div>
                    }
                    </InViewElement>
                </div>
            }
            <div className="career__blocks">
            {props.career?.blocks?.map((block: any, index: number) => {

                const blockName: string = block.name.replace('core/', '');

                switch (blockName) {
                    case 'paragraph':
                        return <ParagraphBlock {...block} key={index} />;
                    case 'heading':
                        return <HeadingBlock {...block} key={index} />;
                    case 'image':
                        return <ImageBlock {...block} key={index} />;
                    case 'list':
                        return <ListBlock {...block} key={index} />;
                    case 'buttons':
                        return <ButtonsBlock {...block} key={index} />;
                    case 'video':
                        return <VideoBlock {...block} key={index} />;
                    case 'quote':
                        return <QuoteBlock {...block} key={index} />;
                    case 'pullquote':
                        return <PullQuoteBlock {...block} key={index} />;
                    case 'verse':
                        return <VerseBlock {...block} key={index} />;
                    case 'embed':
                        return <EmbedBlock {...block} key={index} />;
                    case 'spacer':
                        return <SpacerBlock {...block} key={index} />;
                }
                })}
                {/* <a className='career__expand-button' onClick={toggleIsExpanded}>
                    <Button 
                        text={isExpanded ? 'Show Less' : 'See More'} 
                    />
                </a> */}
            </div>
        </StyledCareer>
    </ThemeProvider>
  );
}

export default Career;
import styled from 'styled-components';
import { orderedList, unorderedList } from '../../global/typography.styles';
import { blockSpacing } from '../../global/spacing.styles';
import { SOLSVGString, SOLSVGStringWhite } from '../../global/icon.styles';
import { colors, themedColor } from '../../global/theme.styles';

export const StyledList = styled.ul`
    ${blockSpacing}
    ${unorderedList}

    & li::before {
        ${themedColor}
        content: ${props => props.theme.textColor.light === colors.black ? `url("data:image/svg+xml; utf8, ${SOLSVGString}")` : `url("data:image/svg+xml; utf8, ${SOLSVGStringWhite}")`};

        @media (prefers-color-scheme: dark) {
            filter: ${props => props.theme.textColor.light !== props.theme.textColor.dark ? 'invert(1)' : ''};
        }
    }
`;

export const StyledOrderedList = styled.ol`
    ${blockSpacing}
    ${orderedList}
`;

export type EmbedTypes = 'youtube' | 'vimeo' | 'instagram' | undefined;

export const getEmbedType = (url: string): EmbedTypes => {
    if (url.includes('://www.youtube.com')) {
        return 'youtube';
    }

    return undefined;
}

export const getYoutubeVideoId = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
}

export const getYoutubeThumbnail = (videoId: string) => {
    return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
}
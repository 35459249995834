import * as React from 'react';
import { StyledButton, StyledLinkButton } from './button.styles';
import { LinkPart } from './link';

export interface IButtonProps {
  disabled?: boolean;
  text?: string;
  url?: string;
  secondary?: boolean;
  onButtonClick?: () => void;
}

export const Button: React.FC<IButtonProps> = props => {

  return (
    <>
    {props.url ? 
      <LinkPart to={props.url}>
        <StyledLinkButton secondary={props.secondary}>
          {props.text ?? 'Link'}
        </StyledLinkButton>
      </LinkPart> :
      <StyledButton 
        secondary={props.secondary} 
        onClick={() => !!props.onButtonClick && props.onButtonClick}
      >
        {props.text ?? 'Send'}
      </StyledButton>
    }
    </>
  );
};

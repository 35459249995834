import styled from 'styled-components';
import { media } from '../../global/media.styles';
import { blockSpacing } from '../../global/spacing.styles';

export interface IStyledColumnsProps {
    columnCount: number;
}

export const StyledColumns = styled.div<IStyledColumnsProps>`
    ${blockSpacing}
    display: grid;
    grid-column-gap: 30px;
    grid-row-gap: 45px;
    grid-template-columns: ${p => `repeat(${Math.floor(p.columnCount / 2)}, 1fr)`};

    @media ${media.tablet} {
        grid-template-columns: ${p => `repeat(${Math.round(p.columnCount / 2)}, 1fr)`};
    }

    @media ${media.tabletLandscape} {
        grid-template-columns: ${p => `repeat(${p.columnCount}, 1fr)`};
    }
`;

import * as React from 'react';
import { StyledCareers } from './careers.styles';
import { graphql, navigate } from 'gatsby';
import Career from '../../parts/career';
import { CareerBlockFragmentFragment, CareerFragmentFragment, CareersBlockFragmentFragment, Maybe } from '../../../gatsby-graphql';
import { useCareers } from '../../hooks/careers.hook';
import { BaseBlockProps } from '../blocks';

export interface ICareersBlockProps extends CareersBlockFragmentFragment, BaseBlockProps {};

export const CareersBlock: React.FC<ICareersBlockProps> = props => {
  const careers = useCareers();
  const careersElementRef = React.useRef<HTMLDivElement>(null);
  const [careerInView, setCareerInView] = React.useState<string>('');
  const [careerObserver, setCareerObserver] = React.useState<IntersectionObserver | null>(null);
  const careerIds = props.innerBlocks && props.innerBlocks.map((career: CareerBlockFragmentFragment) => {
    return career.attributes?.careerId;
  });
  const usedCareers = careerIds && careers?.nodes && careers.nodes.filter((career: Maybe<CareerFragmentFragment>) => {
      if (career && career.databaseId) {
        return careerIds.includes(career.databaseId);
      }
    }
  );
  const sortedCareers = usedCareers && careerIds && usedCareers.sort((first: Maybe<CareerFragmentFragment>, second: Maybe<CareerFragmentFragment>) => {
    if (!!first && !!second) {
      return careerIds.indexOf(first.databaseId) - careerIds.indexOf(second.databaseId);
    }

    return 0
  });

  React.useEffect(() => {
      if ( !careerObserver ) {
        const careerObserverOptions = {
          rootMargin: '0px',
          threshold: [0.8, 0.2]
        };

        // setCareerObserver(new IntersectionObserver(updateCurrentCareer, careerObserverOptions));
      }

      return () => {
          !!careerObserver && careerObserver.disconnect();
      };
  },
  [careerObserver]
  );

  const updateCurrentCareer = React.useCallback(
    (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {

        entries.forEach((entry: IntersectionObserverEntry) => {
            if ( entry.isIntersecting && entry.intersectionRatio > 0.8 ) {
                const currentCareerNode = entry.target;
                const currentCareerId = currentCareerNode.id;
                setCareerInView(currentCareerId);
                navigate(`#${currentCareerId}`);
            }
        });
    },
    [setCareerInView]
  );

  return (
    <StyledCareers ref={careersElementRef}>
      {sortedCareers && sortedCareers.map((career: Maybe<CareerFragmentFragment>, index: number) => {
        return <Career career={career} careers={sortedCareers} observer={careerObserver} index={index} key={index} />
        }
      )}
    </StyledCareers>
  
  );
};

export const CareerBlockFragment = graphql`
fragment CareerBlockFragment on WpSafetyFirstPluginCareerBlock {
  attributes {
    careerId
  }
}
`;

export const CareersBlockFragment = graphql`
    fragment CareersBlockFragment on WpSafetyFirstPluginCareersBlock {
      innerBlocks {
        ...CareerBlockFragment
      }
    }
`;

import styled from 'styled-components';
import { blockSpacing } from '../../global/spacing.styles';
import { layer } from '../../global/theme.styles';
import { StyledButton } from '../../parts/button.styles';
import { StyledLink } from '../../parts/link.styles';

export interface IStyledButtonsProps {
    buttonCount?: number;
}

export const StyledButtons = styled.div<IStyledButtonsProps>`
    ${blockSpacing}
    display: grid;
    grid-template-columns: ${p => p.buttonCount && p.buttonCount > 1 ? '1fr 1fr' : '1fr'};
    grid-column-gap: 30px;
    z-index: ${layer.baseLayer};

    & ${StyledButton},
    & ${StyledLink} {
        grid-column: span 1;
        max-width: none;
        z-index: ${layer.baseLayer};
    }
`;

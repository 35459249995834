import * as React from 'react';
// import { StyledVideo } from './video.styles';
import { graphql } from 'gatsby';
import { ImagePart } from '../../parts/image';
import PlayButtonIcon from '../../svg/play-button-icon.svg';
import { LinkPart } from '../../parts/link';
import { StyledVideo } from './video.styles';
import { VideoBlockFragmentFragment } from '../../../gatsby-graphql';
import { BaseBlockProps } from '../blocks';

export interface IVideoBlockProps extends VideoBlockFragmentFragment, BaseBlockProps { };

export const VideoBlock: React.FC<IVideoBlockProps> = props => {
    const attributes = props.attributes;
    return (
        <StyledVideo>
            <div className="video__image-wrapper">
                <LinkPart to={`#video/internal/${encodeURIComponent(attributes?.videoSrc || '')}`} />
                {!!attributes?.poster ?
                    <ImagePart url={attributes.poster} ratio="double" />
                    :
                    <video width='100%' height="100%" controls>
                        <source src={attributes?.videoSrc || ""} />
                        Your browser does not support the video tag.
                    </video>
                }
                <div className="video__play-button">
                    <PlayButtonIcon />
                </div>
            </div>
            <span>{attributes?.caption}</span>
        </StyledVideo>
    );
};

export const VideoBlockFragment = graphql`
    fragment VideoBlockFragment on WpCoreVideoBlock {
        attributes {
            ... on WpCoreVideoBlockAttributes {
                align
                anchor
                autoplay
                caption
                controls
                id
                loop
                muted
                playsInline
                poster
                preload
                videoSrc: src
            }
        }
    }
`;
